import React, { Component } from "react";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";

export class Gallery extends Component {

  constructor() {
    super();
    this.state = {
      showComponent: false,
      index: 0
    };
    this.onButtonClick = this.onButtonClick.bind(this);
    this.onCloseLightBox = this.onCloseLightBox.bind(this);
  }

  onButtonClick(event) {
    this.setState({
      showComponent: true,
      index: event
    });
  }

  onCloseLightBox() {
    this.setState({
      showComponent: false,
    });
  }

  render() {
    return (
      <div id="portfolio" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Gallery</h2>
          </div>
          <div className="row">
            {this.state.showComponent && this.props.data ?
              <Lightbox images={this.props.data[this.state.index].images} onClose={this.onCloseLightBox}></Lightbox> : <div></div>
            }
            <div className="portfolio-items">
              {this.props.data
                ? this.props.data.map((d, i) => (
                  <div key={`${d.title}-${i}`} className="col-xs-4 col-sm-4 col-md-2 col-lg-3">
                    <div className="portfolio-item">
                      <div className="hover-bg" onClick={this.onButtonClick.bind(this, i)}>
                        <div className="hover-text">
                          <h4>{d.title}</h4>
                        </div>
                        <img
                          src={d.src}
                          className="img-responsive"
                          alt="Project Title"
                        />
                      </div>
                    </div>
                  </div>
                ))
                : "Loading..."}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Gallery;
