import React, { Component } from "react";

export class Products extends Component {
  render() {
    return (
      <div id="products" className="text-center">
        <div className="container">
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2>Products & services</h2>
          </div>
          <div className="row">
            {this.props.data
              ? this.props.data.row1.map((d,i) => (
                  <div  key={`${d.title}-${i}`} className="col-s-6 col-md-3">
                    {" "}
                    <img src={d.path} alt={d.alt} width="120" height="120"/>
                    <h3>{d.title}</h3>
                    <p>{d.text}</p>
                  </div>
                ))
              : "Loading..."}
          </div>
          <div className="row">
            {this.props.data
              ? this.props.data.row2.map((d,i) => (
                  <div  key={`${d.title}-${i}`} className="col-s-6 col-md-3">
                    {" "}
                    <img src={d.path} alt={d.alt} width="120" height="120"/>
                    <h3>{d.title}</h3>
                    <p>{d.text}</p>
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
    );
  }
}

export default Products;
