import React, { Component } from "react";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export class Contact extends Component {

  constructor() {
    super();
    this.state = {
      name: '',
      email: '',
      phone:'',
      message: '',
      openSuccess: false,
      openFail: false
    }
  }

  handleSuccess = () => {
    this.setState({
      name: "",
      email: "",
      phone: "",
      message: "",
      openSuccess: true
    })
  }

  handleFail = () => {
    this.setState({
      openFail: true
    })
  }

  handleSuccessClose = () => {
    this.setState({openSuccess: false})
  }

  handleFailClose = () => {
    this.setState({openFail: false})
  }

  render() {
    return (
      <div>
        <div id="contact">
          <div className="container">
            <div className="col-md-8">
              <div className="row">
                <div className="section-title">
                  <h2>Get In Touch</h2>
                  <p>
                    Please fill out the form below to send us an email and we
                    will get back to you as soon as possible.
                  </p>
                </div>
                <form name="sentMessage" id="contactForm" onSubmit={this.handleSubmit.bind(this)}>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <input
                          type="text"
                          id="name"
                          className="form-control"
                          placeholder="Name"
                          required="required"
                          value={this.state.name} onChange={this.onNameChange.bind(this)}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <input
                          type="email"
                          id="email"
                          className="form-control"
                          placeholder="Email"
                          required="required"
                          value={this.state.email} onChange={this.onEmailChange.bind(this)}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <input
                          type="text"
                          maxLength="10"
                          minLength="10"
                          pattern="^[0-9]{10}$"
                          id="phone"
                          className="form-control"
                          placeholder="Mobile Number"
                          required="required"
                          value={this.state.phone} onChange={this.onMobileChange.bind(this)}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder="Message"
                      required
                      value={this.state.message} onChange={this.onMessageChange.bind(this)}
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  <div id="success"></div>
                  <button type="submit" className="btn btn-custom btn-lg">
                    Send Message
                  </button>
                  <Snackbar open={this.state.openSuccess} 
                  actionText="dismiss"
                  autoHideDuration={6000}
                  onClose={this.handleSuccessClose}>
                    <Alert onClose={this.handleSuccessClose} severity="success" className="notification">
                    Sent! We'll get back to you ASAP 😊
                    </Alert>
                  </Snackbar>
                  <Snackbar open={this.state.openFail}
                  actionText="dismiss"
                  autoHideDuration={6000}
                  onClose={this.handleFailClose}>
                    <Alert onClose={this.handleFailClose} severity="error" className="notification">
                      The message sent successfully!
                    </Alert>
                  </Snackbar>
                </form>
              </div>
            </div>
            <div className="col-md-3 col-md-offset-1 contact-info">
              <div className="contact-item">
                <h3>Contact Info</h3>
                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> Address
                  </span>
                  {this.props.data ? this.props.data.address : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Phone
                  </span>{" "}
                  {this.props.data ? this.props.data.phone : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Email
                  </span>{" "}
                  {this.props.data ? this.props.data.email : "loading"}
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="social">
                  <ul>
                    <li>
                      <a
                        href={this.props.data ? this.props.data.facebook : "/"}
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href={this.props.data ? this.props.data.twitter : "/"}>
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href={this.props.data ? this.props.data.youtube : "/"}>
                        <i className="fa fa-youtube"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="footer">
          <div className="container text-center">
            <p>Design by{" "}
              <a href="https://phantomrndsolutions.com" rel="nofollow">
                Phantom R &amp; D Solutions
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }

  onNameChange(event) {
    this.setState({ name: event.target.value })
  }

  onEmailChange(event) {
    this.setState({ email: event.target.value })
  }

  onMobileChange(event) {
    this.setState({ phone: event.target.value })
  }

  onMessageChange(event) {
    this.setState({ message: event.target.value })
  }

  handleSubmit(event) {
    event.preventDefault();
    const { email: reply_to, ...args } = this.state;
    fetch('https://uojffzrfb8.execute-api.us-east-2.amazonaws.com/Prod/api/contact', {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({ data: { reply_to, ...args } }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then((response) => {
      if (response.status === 200) {
        this.handleSuccess();
      } else if (response.status !== 200) {
        this.handleFail();
      }
    })
  }
}

export default Contact;
