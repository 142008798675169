import React, { Component } from "react";
import Carousel from "react-material-ui-carousel";

export class Header extends Component {
  render() {
    return (
      <header className="header-margin" id="header">
      <Carousel navButtonsAlwaysVisible="true">
        {this.props.data ? this.props.data.map((item, i) => (<div key={`${item.title}-${i}`}>
            <div className="intro" style={{ backgroundImage: "url(" + item.image + ")" }}>
              <div className="overlay">
                <div className="container">
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2 intro-text">

                      <h1>{item.title} <span></span></h1>
                      <p>{item.description}</p>
                      <a
                        href="#products"
                        className="btn btn-custom btn-lg page-scroll">
                        Learn More
                          </a>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>)) : "Loading....." }
      </Carousel>
      </header>
    );
  }
}

export default Header;
