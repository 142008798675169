import React, { Component } from 'react';
import Carousel from "react-material-ui-carousel";
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Paper, Tab } from '@material-ui/core';


export class Projects extends Component {
    state = {
        value: "0",
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    render() {
        return (
            <div id="projects">
                <div className="section-title text-center">
                    <h2>Projects</h2>
                </div>
                <TabContext value={this.state.value}>
                
                    <Paper>
                        <TabList onChange={this.handleChange} 
                            indicatorColor="primary"
                            textColor="#555"
                            centered>
                            <Tab label="Past Local Projects" value="0" className="project-tab" />
                            <Tab label="Past Foriegn Projects" value="1" className="project-tab"/>
                            <Tab label="Ongoing Local Projects" value="2" className="project-tab"/>
                            <Tab label="Ongoing Foriegn Projects" value="3" className="project-tab"/>
                        </TabList>
                    </Paper>
                    {this.props.data ? this.props.data.map((item, i) => (<div key={`${item.title}-${i}`}>
                    <TabPanel value={item.index}>
                    <Carousel navButtonsAlwaysVisible="true">
                    {item.items.map((item, i) => (<div key={`${item.title}-${i}`}>
                        <div className="row">
                            <div className="col-xs-12 col-md-6"> <img src="img/about.jpg" className="img-responsive" alt="" /> </div>
                            <div className="col-xs-12 col-md-6">
                                <div>
                                    <h3>{item.title}</h3>
                                    <p>{item.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>))}
                </Carousel>
                    </TabPanel>
                    </div>)) : "Loading....."}
                </TabContext>
                
            </div>
        )
    }
}

export default Projects
